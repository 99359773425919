import ReactGA from 'react-ga';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../store';
import { AuthActions } from '../storeActions';
import { Auth } from '../../utils/auth';
import * as settingsActions from '../settings/actions';
// import { mxReset, mxSuperProps, mxUserProps } from '../../trackingLayer/utils';

export const onRefreshedToken = accessToken => {
  return { type: AuthActions.REFRESH_TOKEN, accessToken };
};
export const onRefreshedProfile = profile => ({ type: AuthActions.REFRESH_PROFILE, profile });
export const onLogout = () => ({ type: AuthActions.LOGOUT });
export const onAuthFailure = error => ({ type: AuthActions.FAILURE, error });

let auth: Auth;

export const initializeAuth = (): ThunkAction<void, AppState, void, AnyAction> => dispatch => {
  auth = new Auth(dispatch);
};

export const login = (): ThunkAction<void, AppState, void, AnyAction> => async (dispatch, getState) => {
  await auth.login();
  const state = getState();

  const user = state.auth?.profile?.email || state.auth?.profile?.sub;
  const profile = state.auth?.profile;
  if (user) {
    ReactGA.set({ userId: user });
    if (profile) {
      // mxUserProps(user, {
      //   'Account ID': profile['https://claims.cimpress.io/account'],
      //   '$email': state.auth?.profile?.email,
      //   '$name': state.auth?.profile?.name
      // });
      // mxSuperProps({
      //   'Application': 'Production Order Manager',
      //   'userId': user,
      //   'Account ID': profile['https://claims.cimpress.io/account'],
      //   '$email': state.auth?.profile?.email,
      //   '$name': state.auth?.profile?.name,
      //   'Email': state.auth?.profile?.email,
      //   'Name': state.auth?.profile?.name
      // });
    }
    dispatch(settingsActions.getCustomizrSettings());
  }
};

export const logout = () => async dispatch => {
  await auth.logout();
  // mxReset();
  dispatch(onLogout());
};
