import { QuestionsActions } from '../storeActions';
import { AppState } from '../store';
import * as feedbackApi from '../../clients/feedbackApi';
import { Question } from '../../model/campaigns';

const getQuestionsSuccess = (campaignId: string, values: Question[]) => ({
  type: QuestionsActions.GET_QUESTIONS_SUCCESS,
  campaignId,
  data: values
});

const getQuestionsError = (campaignId: string, error) => ({
  type: QuestionsActions.GET_QUESTIONS_ERROR,
  campaignId,
  data: null,
  error: error
});

export const getQuestions = (campaignId: string) => async (dispatch, getState) => {
  dispatch({
    type: QuestionsActions.GET_QUESTIONS_REQUEST,
    campaignId
  });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    const questionsResult = await feedbackApi.getQuestions(accessToken, campaignId);
    if (questionsResult?.error) {
      dispatch(getQuestionsError(campaignId, questionsResult?.error));
    } else {
      const q = questionsResult?.data?._embedded?.question || [];
      q.sort((a, b) => (a.createdAt || '').localeCompare(b.createdAt || ''));
      dispatch(getQuestionsSuccess(campaignId, q));
    }
  } catch (e) {
    dispatch(getQuestionsError(campaignId, e));
  }
};
