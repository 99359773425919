import { AppState } from '../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getCampaigns } from '../../../store/campaigns/actions';
import { getQuestions } from '../../../store/questions/actions';

const questionsNotLoaded = questionsByCampaignId => {
  return !questionsByCampaignId
        || (questionsByCampaignId
            && !questionsByCampaignId.data
            && !questionsByCampaignId.error
            && !questionsByCampaignId.isLoading);
};

export const useCampaignAndQuestions = (campaignId: string) => {
  const campaigns = useSelector((state: AppState) => state.campaigns);
  const questions = useSelector((state: AppState) => state.questions);
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!campaigns.data && !campaigns.isLoading && !campaigns.error && accessToken) {
      dispatch(getCampaigns());
    }
  }, [accessToken, campaigns, dispatch]);

  useEffect(() => {
    if (campaignId && accessToken && questionsNotLoaded(questions.byCampaignId[campaignId])) {
      dispatch(getQuestions(campaignId));
    }
  });

  const campaign = campaigns?.data?.find(c => c.campaignId === campaignId) || {};
  const campaignQuestions = (questions?.byCampaignId[campaignId] || {}).data || [];
  campaignQuestions.sort((a, b) => -a.createdAt.localeCompare(b.createdAt));

  return {
    campaign,
    campaignsLoading: campaigns.isLoading,
    campaignsError: campaigns.error,
    campaignQuestions,
    questionsLoading: (questions?.byCampaignId[campaignId] || {}).isLoading,
    questionsError: (questions?.byCampaignId[campaignId] || {}).error
  };
};
