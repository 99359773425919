import React, { useState } from 'react';
import { colors, Tooltip } from '@cimpress/react-components';
import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

interface CopyToClipboardIconProps {
  toCopy: string;
}

export const CopyToClipboardIcon: React.FC<CopyToClipboardIconProps> = ({ toCopy }) => {
  const { t } = useTranslation();
  const [iconState, setIconState] = useState({
    iconWeight: 'regular',
    iconColor: colors.ocean.base
  });

  const copyToClipboard = () => {
    copy(toCopy);
    setIconState({
      iconWeight: 'fill',
      iconColor: colors.success.base
    });
    setTimeout(() => {
      setIconState({
        iconWeight: 'regular',
        iconColor: colors.ocean.base
      });
    }, 1000);
  };

  return <Tooltip contents={t('copy')}>
    <span onClick={() => copyToClipboard()} style={{ cursor: 'pointer' }}>
      {iconState.iconWeight === 'regular'
        ? <IconCopy style={{ verticalAlign: 'middle' }} color={iconState.iconColor} weight={iconState.iconWeight}/>
        : <IconCheckCircleAlt style={{ verticalAlign: 'middle' }} color={iconState.iconColor} weight={iconState.iconWeight}/>}
    </span>
  </Tooltip>;
};
