import React from 'react';
import { Header } from '@cimpress-technology/react-platform-header/lib/Header';
import * as authActions from '../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import { AppSettings } from '../components/settings/appSettings';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoudspeakerEnvironment, LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';

export const HeaderX: React.FC = () => {
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const profile = useSelector((state: AppState) => state.auth.profile);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const appLinks = [
    {
      id: 'menuLinkSettings',
      type: 'default',
      content: <AppSettings accessToken={accessToken} />
    }
  ];

  const loggedIn = !!accessToken;

  return (
    <Header
      appTitle={<>
        <Link to={'/'}>Feedback UI</Link>
      </>}
      profile={profile}
      mailToAddress="apimanagement@cimpress.com"
      appId={'FeedbackUI'}
      isLoggedIn={loggedIn}
      accessToken={accessToken}
      showLeftNav={loggedIn}
      showLogo={loggedIn}
      onLogInClicked={() => dispatch(authActions.login())}
      onLogOutClicked={() => dispatch(authActions.logout())}
      responsive={true}
      appLinks={accessToken ? appLinks : []}
      useBranding={true}
      notifications={[(
        <LoudspeakerIcon key={1}
          accessToken={accessToken}
          channelIds={['customer-feedback']}
          title={t('loudspeakerModal.title')}
          language={i18n.language}
          environment={LoudspeakerEnvironment.Production}
        />
      )]}
    />
  );
};
