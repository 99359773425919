import React, { useState } from 'react';
import { Question, QuestionType } from '../../../model/campaigns';
import { Alert, Button, Checkbox, Select, TextField } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import * as feedbackAPI from '../../../clients/feedbackApi';
import { AppState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { ErrorDetails } from '../../../store/commonTypes';

export interface QuestionFormProps {
  campaignId: string;
  question: Question;
  onCancel: (questionId: string|null|undefined) => void;
  onSave: (questionId: string|null|undefined) => void;
}

enum QuestionSingleChoiceParameters {
  radio = 'radio'
}

enum QuestionMultipleChoiceParameters {
  checkbox = 'checkbox'
}

const getQuestionTypeOptions = (t, selectedType: QuestionType) => {
  const all = ['Stars', 'TextField', 'SingleChoice', 'MultipleChoice']
    .map(type => ({ value: type, label: t(`questions.fields.type.${type}`) }));

  return {
    questionTypeOptions: all,
    selectedQuestionType: all.find(a => a.value === selectedType)
  };
};

const renderQuestionType = (t, questionType: QuestionType, updateCurrent: (key, value) => void) => {
  const { questionTypeOptions, selectedQuestionType } = getQuestionTypeOptions(t, questionType);

  const initialQuestionParameters = {
    SingleChoice: {
      style: Object.keys(QuestionSingleChoiceParameters)[0]
    },
    MultipleChoice: {
      style: Object.keys(QuestionMultipleChoiceParameters)[0]
    }
  };

  return <Select
    options={questionTypeOptions}
    value={selectedQuestionType}
    required
    label={t('questions.fields.type.label')}
    onChange={e => {
      const typed = e as { label: string; value: string};
      if (typed.value) {
        updateCurrent('type', typed.value);
        updateCurrent('parameters', initialQuestionParameters[typed.value]);

        if (typed.value === QuestionType.TextField) {
          updateCurrent('includeFreeFormField', false);
          updateCurrent('freeFormFieldTitle', '');
        }
      }
    }}
  />;
};

const renderParameters = (t, currentQuestion: Question, updateCurrent: (key, value) => void) => {
  const options = Object.values(currentQuestion.type === QuestionType.SingleChoice
    ? QuestionSingleChoiceParameters
    : QuestionMultipleChoiceParameters).map(v => ({ value: v, label: t(`questions.fields.style.${v}`) }));

  return currentQuestion.type === QuestionType.SingleChoice || currentQuestion.type === QuestionType.MultipleChoice
    ? <>
      <Select
        options={options}
        value={options.find(opt => opt.value === currentQuestion.parameters?.style) || null}
        required
        label={t('questions.fields.style.label')}
        onChange={e => {
          const typed = e as { label: string; value: string };
          if (typed.value) {
            updateCurrent('parameters', {
              ...currentQuestion.parameters, ...{
                style: typed.value
              }
            });
          }
        }}
      />

      <TextField
        style={{ resize: 'vertical' }}
        type={'textarea'}
        value={currentQuestion.parameters?.options?.map(opt => opt.value).join('\n')}
        required
        label={t('questions.fields.options.label')}
        onBlur={() => {
          updateCurrent('parameters', {
            ...currentQuestion.parameters, ...{
              options: currentQuestion.parameters?.options?.map(opt => ({ ...opt, value: opt.value.trim() }))
            } });
        }}
        onChange={e => {
          updateCurrent('parameters', {
            ...currentQuestion.parameters, ...{
              options: e.target.value === '' ? undefined : e.target.value.split('\n').map(line => ({ value: line.trimStart() }))
            }
          });
        }} />
    </>
    : null;
};

const renderFreeFormFields = (t, currentQuestion: Question, updateCurrent: (key, value) => void) => {
  return <>
    {currentQuestion.type !== QuestionType.TextField
      ? <Checkbox
        label={t('questions.fields.includeFreeFormField.label')}
        checked={currentQuestion.includeFreeFormField}
        onChange={() => {
          updateCurrent('includeFreeFormField', !currentQuestion.includeFreeFormField);
        } }
      />
      : null}

    {currentQuestion.includeFreeFormField
      ? <TextField
        value={currentQuestion.freeFormFieldTitle}
        required={currentQuestion.includeFreeFormField}
        disabled={!currentQuestion.includeFreeFormField}
        label={t('questions.fields.freeFormText.label')}
        onChange={e => {
          updateCurrent('freeFormFieldTitle', e.target.value);
        }}
      />
      : null}
  </>;
};

export const QuestionForm: React.FC<QuestionFormProps> = ({ campaignId, question, onCancel, onSave }) => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const [currentQuestion, setCurrentQuestion] = useState(question);
  const [error, setError] = useState(undefined as unknown as ErrorDetails);

  const updateCurrent = (key, value) => {
    setCurrentQuestion(current => Object.assign({}, current, { [key]: value }));
  };

  return <div className={'question-info-container'}>
    <TextField
      value={currentQuestion.title}
      required
      label={t('questions.fields.title.label')}
      onChange={e => {
        updateCurrent('title', e.target.value);
      }}
    />

    {renderQuestionType(t, currentQuestion.type, updateCurrent)}

    {renderParameters(t, currentQuestion, updateCurrent)}

    {renderFreeFormFields(t, currentQuestion, updateCurrent)}

    <div className={'action-bar'}>
      <Button type={'default'} onClick={onCancel}>{t('questions.buttonCancel')}</Button>
      &nbsp;&nbsp;
      <Button type={'primary'}
        disabled={(currentQuestion.type === 'SingleChoice' || currentQuestion.type === 'MultipleChoice')
          && (!currentQuestion.parameters?.style || !currentQuestion.parameters.options?.length)}
        onClick={async () => {
          const result = question.questionId === null
            ? await feedbackAPI.postQuestions(accessToken, campaignId, currentQuestion)
            : await feedbackAPI.putQuestions(accessToken, campaignId, question.questionId, currentQuestion);
          if (result?.error) {
            setError(result?.error);
          } else {
            setError(undefined as unknown as ErrorDetails);
            onSave(result?.data?.questionId);
          }
        }
        }>
        {t('questions.buttonSave')}
      </Button>
      {error
        ? <Alert type={'danger'} title={error.message} message={error.details} onDismiss={() => setError(undefined as unknown as ErrorDetails)}/>
        : null}
    </div>

  </div>;
};
