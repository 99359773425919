import React, { useState } from 'react';
import { Button, Modal, TextField } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import * as feedbackAPI from '../clients/feedbackApi';

import moment, { Moment } from 'moment';
import { AppState } from '../store/store';
import { ErrorDetails } from '../store/commonTypes';
import { ErrorAlert } from './errorAlert';
import { renderTwoColumns } from './utils';

export interface NewCampaignData {
  title: string;
  startDate: string;
  endDate?: string;
}

export interface NewCampaignModalProps {
  accessToken: string;
  show: boolean;
  onCancel: () => void;
  onSave: (campaignId: string|undefined) => void;
}

export const NewCampaignModal: React.FC<NewCampaignModalProps> = ({ show, onCancel, onSave }) => {
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState({ title: '', startDate: null, endDate: null } as unknown as NewCampaignData);
  const [error, setError] = useState(null as unknown as ErrorDetails);
  const { accessToken } = useSelector((state: AppState) => state.auth);

  const updateField = (name, value) => {
    setCampaign(Object.assign({}, campaign, { [name]: value }));
  };

  const drawerContent = <>
    <ErrorAlert error={error}/>
    <TextField value={campaign.title} required label={t('campaigns.fields.title.label')}
      onChange={e => updateField('title', e.target.value)}/>
    {renderTwoColumns(<>
      <h6 className='field-caption'>{t('campaigns.fields.startDate.label')}</h6>
      <DatePicker readonly utc dateFormat={'YYYY-MM-DD'} timeFormat={false} value={moment(campaign.startDate)} onChange={e => updateField('startDate', (e as Moment).toISOString())} />
    </>, <>
      <h6 className='field-caption'>{t('campaigns.fields.endDate.label')}</h6>
      <DatePicker readonly utc dateFormat={'YYYY-MM-DD'} timeFormat={false} value={moment(campaign.endDate)} onChange={e => updateField('endDate', (e as Moment).toISOString())} />
    </>)}
  </>;
  return <Modal
    show={show}
    title={t('campaigns.newModal.title')}
    closeButton={true}
    onRequestHide={onCancel}
    footer={<div>
      <Button onClick={onCancel}>{t('campaigns.newModal.buttonClose')}</Button>
      <Button type={'primary'}
        disabled={!campaign || !campaign.title || !campaign.startDate}
        onClick={async () => {
          const result = await feedbackAPI.postCampaigns(accessToken, {
            title: campaign.title,
            startDate: campaign.startDate,
            endDate: campaign.endDate
          });

          if (!result?.error) {
            if (onSave) {
              onSave(result?.data?.campaignId);
            }
          } else {
            setError(result?.error);
          }
        }}>
        {t('campaigns.newModal.buttonCreate')}
      </Button>
    </div>
    } >
    {drawerContent}
  </Modal>;
};
