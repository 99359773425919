import React from 'react';
import { Alert } from '@cimpress/react-components';
import { ErrorDetails } from '../store/commonTypes';

export interface ErrorAlertProps {
    error: ErrorDetails;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  return <>
    <Alert
      type={'danger'}
      title={error.message}
      message={error.details}
    />
  </>;
};
