
export interface CampaignsResults {
    _links: ResultsLinks;
    _embedded: {
        campaign: Campaign[];
    };
}

export interface QuestionsResults {
    _links: ResultsLinks;
    _embedded: {
        question: Question[];
    };
}

export interface ResultsLinks {
    self?: LinkObject;
    next?: LinkObject;
    prev?: LinkObject;
}

export interface Campaign extends CampaignData{
    campaignId: string;
    createdBy?: string;
    createdAt?: string;
    updatedBy?: string;
    updatedAt?: string;
    totalResponses?: number;
    _links: CampaignLinks;
}

export interface CampaignData {
    title: string;
    startDate: string;
    endDate?: string;
    offerFollowup?: boolean;
    status?: CampaignStatus;
}

export interface Question extends QuestionData {
    questionId: string|null;
    createdBy?: string;
    createdAt?: string;
    totalResponses?: number;
    _links: QuestionLinks;
}

export interface QuestionData {
    title: string;
    type: QuestionType;
    includeFreeFormField: boolean;
    freeFormFieldTitle: string;
    parameters: Record<string, any>;
}

export interface CampaignLinks {
    self: LinkObject;
    questions: LinkObject;
    coamGroup: LinkObject;
}

export interface QuestionLinks {
    self: LinkObject;
    campaign: LinkObject;
}

export interface LinkObject {
    href: string;
}

export enum CampaignStatus {
    Auto = 'Auto',
    Suspended = 'Suspended'
}

export enum QuestionType {
    Stars = 'Stars',
    TextField = 'TextField',
    SingleChoice = 'SingleChoice',
    MultipleChoice = 'MultipleChoice'
}

export interface CampaignReportResults {
    _embedded: {
        response: CampaignReportResultLine[];
    };
}

export interface CampaignReportResultLine {
    campaignId: string;
    questionId: string;
    versionId: string;
    questionType: QuestionType;
    questionTitle: string;
    questionTextTitle: string;
    userInfo: {
        userId: string;
        accountID: string;
        email: string;
    };
    value: string;
    text: string;
    extra: Record<string, any>;
}
