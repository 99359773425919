import React, { useState } from 'react';
import { Button, colors, Modal } from '@cimpress/react-components';
import { IconButton } from '../iconButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { ErrorDetails } from '../../store/commonTypes';
import { ErrorAlert } from '../errorAlert';
import * as feedbackAPI from '../../clients/feedbackApi';
import { AppState } from '../../store/store';

export interface DeleteCampaignActionProps {
  disabled: boolean;
  campaignId: string;
  campaignTitle: string;
  onDeleted: () => void;
}

const modalFooter = (t, deleting, closeModal, confirmDelete) => {
  return <>
    <Button disabled={deleting} type='default' onClick={closeModal}>
      {t('campaigns.remove.buttonCancel')}
    </Button>
  &nbsp;
    <Button disabled={deleting} type='primary' onClick={confirmDelete}>
      {t('campaigns.remove.buttonConfirm')}
    </Button>
  </>;
};

const modalContent = (t, campaignTitle, error) => {
  return <>
    <ErrorAlert error={error}/>
    <div>{t('campaigns.remove.modalDescription')}</div>
    <br/>
    <div>{t('campaigns.remove.modalDescription1')}</div>
    <br/>
    <strong>{campaignTitle}</strong>
  </>;
};

export const DeleteCampaignAction: React.FC<DeleteCampaignActionProps> = ({ disabled, campaignId, campaignTitle, onDeleted }) => {
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const [showDeleteQuestionConfirmation, setShowDeleteQuestionConfirmation] = useState(false);
  const [error, setError] = useState(null as unknown as ErrorDetails);
  const [deleting, setDeleting] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setShowDeleteQuestionConfirmation(true);
  };

  const closeModal = () => {
    setShowDeleteQuestionConfirmation(false);
    setError(null as unknown as ErrorDetails);
  };

  const confirmDelete = async () => {
    setDeleting(true);
    setError(null as unknown as ErrorDetails);
    const res = await feedbackAPI.deleteCampaign(accessToken, campaignId);
    setDeleting(false);
    if (res?.error) {
      setError(res.error);
    } else {
      setShowDeleteQuestionConfirmation(false);
      if (onDeleted) {
        onDeleted();
      }
    }
  };

  return <>

    <IconButton
      size={'3x'}
      color={disabled ? colors.alloy : colors.danger.base}
      tooltip={t('campaigns.buttonRemove')}
      onClick={disabled ? undefined : showModal}
      icon={IconBin}/>

    <Modal
      bsStyle={'danger'}
      show={showDeleteQuestionConfirmation}
      onRequestHide={closeModal}
      title={t('campaigns.remove.modalTitle')}
      closeButton={!deleting}
      footer={modalFooter(t, deleting, closeModal, confirmDelete)}>
      {modalContent(t, campaignTitle, error)}
    </Modal>

  </>;
};
