import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/app';
import * as serviceWorker from './serviceWorker';

import { i18n } from './locales/i18n';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { FullStory } from '@cimpress-technology/react-reporting-redux';
import { store } from './store/store';

ReactDOM.render(
  <Provider store={store}>
    <FullStory fsOptions={{ org: '97CT5', allowLocalhost: false, debug: false }} selector={state => state.auth}/>
    <I18nextProvider i18n={i18n}>
      <App/>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
