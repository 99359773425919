import React from 'react';
import { shapes } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

export interface LoadingProps {
    message?: string;
}

export const Loading: React.FC<LoadingProps> = ({ message }) => {
  const { t } = useTranslation();
  const { Spinner } = shapes;

  return <div className='flexContainer' style={{ alignItems: 'left' }}>
    <div className={'flexChild'}>
      <Spinner size={'medium'}/>
    </div>
    <div className={'flexChild'}>
      &nbsp;
    </div>
    <div className='flexChildGrow' style={{ lineHeight: '38px' }}>
      {message ?? t('loading')}
    </div>
  </div>;
};
