import React, { useEffect, useState } from 'react';
import { Campaign } from '../../model/campaigns';
import { Table, Tooltip } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface CampaignTableProps {
    campaigns: Campaign[];
}

const getColumns = t => [
  {
    id: 'title',
    accessor: 'title',
    Header: t('campaigns.columns.title'),
    // eslint-disable-next-line react/display-name
    Cell: r => <Link to={`/campaigns/${r.original.campaignId}`}>{r.original.title}</Link>
  }, {
    id: 'startDate',
    accessor: 'startDate',
    Header: t('campaigns.columns.startDate'),
    Cell: r => r.original.startDate
  }, {
    id: 'endDate',
    accessor: 'endDate',
    Header: t('campaigns.columns.endDate'),
    Cell: r => r.original.endDate
  },
  {
    id: 'status',
    accessor: 'status',
    Header: t('campaigns.columns.status'),
    Cell: r => r.original.status
  },
  {
    id: 'totalResponses',
    accessor: 'totalResponses',
    Header: t('campaigns.columns.responses'),
    Cell(r) {
      return <>
        <Tooltip contents={t('campaigns.totalResponsesTooltip')}>
          <div className={`label label-${r.original.totalResponses > 0 ? 'info' : 'default'}`}>
            {t('campaigns.totalResponses', { count: r.original.totalResponses || 0 })}
          </div>
        </Tooltip>
      </>;
    }
  }
];

const cmpInt = (a, b) => (a || 0) - (b || 0);
const cmpStr = (a, b) => (a || '').localeCompare(b || '');

export const CampaignTable: React.FC<CampaignTableProps> = ({ campaigns }) => {
  const { t } = useTranslation();

  const [localCampaigns, setLocalCampaigns] = useState([] as Campaign[]);

  useEffect(() => {
    setLocalCampaigns(campaigns.slice()
      .sort((a, b) => (b.totalResponses || 0) - (a.totalResponses || 0)));
  }, [campaigns]);

  return <div>
    <Table
      loading={false}
      columns={getColumns(t)}
      data={localCampaigns}
      page={0}
      pageSize={campaigns.length}
      minRows={campaigns.length ? 0 : 10} // we need the 10 minimum rows to show the spinner properly on initial table load
      showPaginationBottom={false}
      showPaginationTop={false}
      multiSort={false}
      onSortedChange={newSorted => {
        const rule = newSorted[0];
        const newList = localCampaigns.slice()
          .sort((a, b) => {
            return (rule.desc ? -1 : 1) * ((rule.id === 'totalResponses')
              ? cmpInt(a[rule.id], b[rule.id])
              : cmpStr(a[rule.id], b[rule.id]));
          });
        setLocalCampaigns(newList);
      }}
      pageSizeOptions={[]}
      selectable={false}
      keyField={'itemId'}
      manual
    />
  </div>;
};
