/* eslint prefer-template: */
import React from 'react';
import { Drawer, Button, CodeBlock } from '@cimpress/react-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { CopyToClipboardIcon } from './copyToClipboardIcon';
import { InlineFeedback } from '@cimpress-technology/react-feedback';
import { AppState } from '../store/store';

export interface CodeDrawerProps {
  campaignId?: string;
  questionId?: string;
  show: boolean;
  onHide: () => void;
}

const getReactComponentCode = (cmp, props, withImport = true, withClosing = false): string => {
  let importCode = '';
  if (withImport) {
    importCode = `import { ${cmp} } from "@cimpress-technology/react-feedback"\n\n`;
  }

  let code = `<${cmp} \n`;
  Object.keys(props).forEach(k => {
    if (props[k]) {
      code += `  ${k}={"${props[k]}"}\n`;
    }
  });
  if (withClosing) {
    code += `>\n  [children]\n</${cmp}>`;
  } else {
    code += '/>\n';
  }
  return importCode + code;
};

const renderFeedbackForm = (accessToken, campaignId, questionId) => {
  return <InlineFeedback
    accessToken={accessToken}
    campaignId={'a29754bc-0aea-11eb-b7c8-02288b18e4b1'}
    questionId={'359a3994-1213-11eb-831f-0a6d98ccf6f1'}
    extraFeedbackData={{
      page: 'Code Drawer',
      campaignId: campaignId || '',
      questionId: questionId || ''
    }}
  />;
};

const MyCodeBlock = ({ code }) => {
  return <div className={'code-block clearfix'}>
    <div className={'code-copy-button'}>
      <CopyToClipboardIcon toCopy={code}/>
    </div>
    <CodeBlock code={code}/>
  </div>;
};

// eslint-disable-next-line max-lines-per-function
export const CodeDrawer: React.FC<CodeDrawerProps> = ({ campaignId, questionId, show, onHide }) => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);

  const drawerContent = <>

    <h4>{t('usage.package.title')}</h4>
    <p>{t('usage.package.description')}</p>
    <MyCodeBlock code={'npm i @cimpress-technology/react-feedback'}/>

    <h4>{t('usage.sticker.title')}</h4>
    <p>{t('usage.sticker.description')}</p>
    <MyCodeBlock code={getReactComponentCode('FeedbackSticker', {
      title: 'Feedback?',
      accessToken: 'accessToken',
      campaignId: campaignId,
      questionId: questionId
    }, false, false)}/>

    <h4>{t('usage.trigger.title')}</h4>
    <p>{t('usage.trigger.description')}</p>
    <MyCodeBlock code={getReactComponentCode('FeedbackWrapper', {
      accessToken: 'accessToken',
      campaignId: campaignId,
      questionId: questionId
    }, false, true)}/>

    <h4>{t('usage.inline.title')}</h4>
    <p>{t('usage.inline.description')}</p>
    <MyCodeBlock code={getReactComponentCode('InlineFeedback', {
      accessToken: 'accessToken',
      campaignId: campaignId,
      questionId: questionId
    }, false, false)}/>

    <h4>{t('usage.floating.title')}</h4>
    <p>{t('usage.floating.description')}</p>
    <MyCodeBlock code={getReactComponentCode('FloatingFeedback', {
      accessToken: 'accessToken',
      campaignId: campaignId,
      questionId: questionId
    }, false, false)}/>

  </>;

  return <Drawer
    closeOnClickOutside
    show={show}
    header={<h4>{t('usage.drawerTitle')}</h4>}
    onRequestHide={onHide}
    footer={<div>
      {renderFeedbackForm(accessToken, campaignId, questionId)}
      <Button onClick={onHide}>
        {t('usage.buttonClose')}
      </Button>
    </div>
    } >
    {drawerContent}
  </Drawer>;
};
