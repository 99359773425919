import React from 'react';
import { Question, QuestionType } from '../../../model/campaigns';
import { Tooltip } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../iconButton';
import { useHistory } from 'react-router';
import IconPencilCircle from '@cimpress-technology/react-streamline-icons/lib/IconPencilCircle';
import { DeleteQuestionAction } from '../../actions/deleteQuestionAction';
import { PreviewQuestionAction } from '../../actions/previewQuestionAction';
import { GetCodeQuestionAction } from '../../actions/getCodeQuestionAction';

export interface QuestionInfoProps {
  disabled?: boolean;
  campaignId: string;
  question: Question;
  onEdit: (id) => void;
  onDelete: () => void;
}

const renderQuestionActions = (t, disabled, campaignId, question, onEdit, onDelete) => {
  return <div className={'accordion-action-list'} >

    <PreviewQuestionAction
      disabled={disabled}
      campaignId={campaignId}
      questionId={question.questionId || ''}
    />

    <GetCodeQuestionAction
      disabled={disabled}
      campaignId={campaignId}
      questionId={question.questionId || ''}
    />

    <IconButton
      size={'2x'}
      disabled={disabled}
      tooltip={t('questions.buttonEdit')}
      onClick={() => {
        onEdit(question.questionId);
      }} icon={IconPencilCircle}/>

    <DeleteQuestionAction
      disabled={disabled}
      campaignId={campaignId}
      questionId={question.questionId as string}
      questionTitle={question.title}
      onDeleted={onDelete}
    />

  </div>;
};

const renderReadonly = (title, content) => {
  return <>
    <h6>{title}</h6>
    <h3>{content}</h3>
  </>;
};

export const QuestionInfo: React.FC<QuestionInfoProps> = ({ campaignId, question, disabled, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const renderReadOnlyQuestion = () => {
    return <>
      {renderReadonly(t('questions.fields.title.label'), question.title)}
      {renderReadonly(t('questions.fields.type.label'), t(`questions.fields.type.${question.type}`))}
      {(question.type !== QuestionType.TextField && !question.includeFreeFormField
        ? renderReadonly(t('questions.fields.includeFreeFormField.label'), t('no'))
        : null)}
      {question.type !== QuestionType.TextField && question.includeFreeFormField
        ? renderReadonly(t('questions.fields.freeFormText.label'), question.freeFormFieldTitle)
        : null}
      <div className={'pull-right'}>
        <Tooltip contents={t('questions.totalResponsesTooltip')}>
          <div className={'label label-info'} role='button' onClick={() => {
            history.push(`/campaigns/${campaignId}/results`);
          }}>
            {t('questions.totalResponses', { count: question.totalResponses || 0 })}
          </div>
        </Tooltip>
      </div>
    </>;
  };

  return <div className={'question-container clearfix'}>
    {renderQuestionActions(t, disabled, campaignId, question, onEdit, onDelete)}
    <div className={'question-info-container'}>
      {renderReadOnlyQuestion() }
    </div>

  </div>;
};
