/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Campaign, CampaignStatus } from '../../model/campaigns';
import { Button, Checkbox, TextField, Tooltip } from '@cimpress/react-components';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/store';
import * as feedbackAPI from '../../clients/feedbackApi';
import { getCampaigns } from '../../store/campaigns/actions';

export interface CampaignInfoProps {
    campaign: Campaign;
    onSaving: (isSaving) => void;
}

const renderTitle = (t, disabled: boolean, currentCampaign: Campaign, updateCurrent) => {
  return <>
    <h6 className='field-caption'>{t('campaigns.fields.title.label')}</h6>
    <h2>
      <TextField value={currentCampaign.title} disabled={disabled} onChange={e => {
        updateCurrent('title', e.target.value);
      }}/>
    </h2>
  </>;
};

const renderStartDate = (t, disabled: boolean, currentCampaign: Campaign, updateCurrent) => {
  return <>
    <h6 className='field-caption'>{t('campaigns.fields.startDate.label')}</h6>
    <div className='form-group'>
      <DatePicker utc timeFormat={false} disabled={disabled}
        value={currentCampaign.startDate ? moment(currentCampaign.startDate) : undefined}
        onChange={m => {
          updateCurrent('startDate', m.toString());
        }} />
    </div>
  </>;
};

const renderEndDate = (t, disabled: boolean, currentCampaign: Campaign, updateCurrent) => {
  return <>
    <h6 className='field-caption'>{t('campaigns.fields.endDate.label')}</h6>
    <div className='form-group'>
      <DatePicker utc timeFormat={false} disabled={disabled}
        value={currentCampaign.endDate ? moment(currentCampaign.endDate) : undefined}
        onChange={m => {
          updateCurrent('endDate', m.toString());
        }} />
    </div>
  </>;
};

const renderSuspend = (t, isSaving: boolean, isSuspended: boolean, updateCurrent) => {
  return <>
    <Checkbox
      label={t('campaigns.fields.isSuspended.label')}
      checked={isSuspended}
      disabled={isSaving}
      onChange={() => {
        updateCurrent('status', !isSuspended ? CampaignStatus.Suspended : CampaignStatus.Auto);
      } }
    />
  </>;
};

const renderOfferFollowup = (t, isSaving: boolean, offerFollowup: boolean|undefined, updateCurrent) => {
  return <>
    <Checkbox
      label={<Tooltip contents={t('campaigns.fields.offerFollowup.tooltip')}>
        {t('campaigns.fields.offerFollowup.label')}
      </Tooltip>}
      checked={offerFollowup}
      disabled={isSaving}
      onChange={() => {
        updateCurrent('offerFollowup', !offerFollowup);
      } }
    />
  </>;
};

const renderSaveCancel = (t, isSaving: boolean, onCancel: () => void, onSave: () => void) => {
  return <div style={{ textAlign: 'right' }}>
    <Button type={'default'} disabled={isSaving} onClick={onCancel}>
      {t('campaigns.buttonCancel')}
    </Button>
    &nbsp;
    <Button type={'primary'} disabled={isSaving} onClick={onSave}>
      {t('campaigns.buttonSave')}
    </Button>
    &nbsp;
  </div>;
};

export const CampaignForm: React.FC<CampaignInfoProps> = ({ campaign, onSaving }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: AppState) => state.auth);
  const [currentCampaign, setCurrentCampaign] = useState(campaign || {});
  const [initialCampaign, setInitialCampaign] = useState(campaign || {});
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    setInitialCampaign(campaign);
    if (!currentCampaign.campaignId) {
      setCurrentCampaign(campaign);
    }
  }, [campaign]);

  const saveCampaign = async () => {
    setSaving(true);
    if (onSaving) {
      onSaving(true);
    }
    const res = await feedbackAPI.putCampaigns(accessToken, currentCampaign.campaignId, currentCampaign);
    if (!res?.error) {
      dispatch(getCampaigns());
    }
    setSaving(false);
    if (onSaving) {
      onSaving(false);
    }
  };

  const updateCurrent = (key, value) => {
    const nq = Object.assign({}, currentCampaign, { [key]: value });
    setCurrentCampaign(nq);
  };

  return <>
    {renderTitle(t, isSaving, currentCampaign, updateCurrent)}
    {renderStartDate(t, isSaving || (currentCampaign.status === CampaignStatus.Suspended), currentCampaign, updateCurrent)}
    {renderEndDate(t, isSaving || (currentCampaign.status === CampaignStatus.Suspended), currentCampaign, updateCurrent)}
    {renderSuspend(t, isSaving, (currentCampaign.status === CampaignStatus.Suspended), updateCurrent)}
    {renderOfferFollowup(t, isSaving, currentCampaign.offerFollowup, updateCurrent)}
    {JSON.stringify(currentCampaign) !== JSON.stringify(initialCampaign)
      ? renderSaveCancel(t, isSaving, () => setCurrentCampaign(initialCampaign), saveCampaign)
      : null}
    <br/>
  </>;
};
