import { CampaignsActionTypes } from './types';
import { CampaignsActions } from '../storeActions';
import { Campaign } from '../../model/campaigns';

export interface CampaignsState {
  isLoading: boolean;
  data?: Campaign[] | null;
  error?: any;
}

const initialState: CampaignsState = {
  isLoading: false,
  data: undefined,
  error: undefined
};

export function campaignsReducer(state = initialState, action: CampaignsActionTypes): CampaignsState {
  switch (action.type) {
    case CampaignsActions.GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null
      };
    case CampaignsActions.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case CampaignsActions.GET_CAMPAIGNS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
}
