import { apiRequest, ErrorResponse, SuccessResponse } from './share/apiClient';
import { CampaignData, CampaignStatus, QuestionData, CampaignReportResults, Campaign } from '../model/campaigns';
import moment from 'moment';
import { isProd } from '../utils/environmentProvider';

const baseUrl = isProd ? 'https://api.feedback.cimpress.io' : 'https://int-api.feedback.cimpress.io';

const cloneAndFix = (oCampaignData: CampaignData) => {
  const campaignData: CampaignData = JSON.parse(JSON.stringify(oCampaignData));
  campaignData.startDate = moment.utc(campaignData.startDate).format('YYYY-MM-DD HH:MM:SS');
  if (campaignData.endDate) {
    campaignData.endDate = moment.utc(campaignData.endDate).format('YYYY-MM-DD HH:MM:SS');
  }
  if (!campaignData.status) {
    campaignData.status = CampaignStatus.Auto;
  }
  return campaignData;
};

export const getCampaigns = async (accessToken: string) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>(accessToken, {
      url: `${baseUrl}/v1/campaigns`,
      method: 'get',
      timeout: 30 * 1000
    });
  }
  return null;
};

export const getQuestions = async (accessToken: string, campaignId: string) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>(accessToken, {
      url: `${baseUrl}/v1/campaigns/${campaignId}/questions`, method: 'get',
      timeout: 30 * 1000
    });
  }
  return null;
};

export const getReport = async (accessToken: string, campaignId: string) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<CampaignReportResults>(accessToken, {
      url: `${baseUrl}/v1/reports`,
      query: {
        campaignId: campaignId
      },
      method: 'get',
      timeout: 30 * 1000
    });
  }
  return null;
};

export const postQuestions = async (accessToken: string, campaignId: string, questionData: QuestionData) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>(accessToken, {
      url: `${baseUrl}/v1/campaigns/${campaignId}/questions`,
      method: 'post',
      timeout: 30 * 1000,
      data: questionData
    });
  }
  return null;
};

export const putQuestions = async (accessToken: string, campaignId: string, questionId: string, questionData: QuestionData) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>(accessToken, {
      url: `${baseUrl}/v1/campaigns/${campaignId}/questions/${questionId}`,
      method: 'put',
      timeout: 30 * 1000,
      data: questionData
    });
  }
  return null;
};

export const deleteQuestion = async (accessToken: string, campaignId: string, questionId: string) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>(accessToken, {
      url: `${baseUrl}/v1/campaigns/${campaignId}/questions/${questionId}`,
      method: 'delete',
      timeout: 30 * 1000
    });
  }
  return null;
};

export const postCampaigns = async (
  accessToken: string,
  oCampaignData: CampaignData
): Promise<SuccessResponse<Campaign> | ErrorResponse<Campaign> | null> => {
  if (accessToken) {
    const campaignData = cloneAndFix(oCampaignData);

    // eslint-disable-next-line no-return-await
    return await apiRequest<Campaign>(accessToken, {
      url: `${baseUrl}/v1/campaigns`,
      method: 'post',
      timeout: 30 * 1000,
      data: campaignData
    });
  }
  return null;
};

export const putCampaigns = async (
  accessToken: string,
  campaignId: string,
  oCampaignData: CampaignData
): Promise<SuccessResponse<Campaign> | ErrorResponse<Campaign> | null> => {
  if (accessToken) {
    const campaignData = cloneAndFix(oCampaignData);

    // eslint-disable-next-line no-return-await
    return await apiRequest<Campaign>(accessToken, {
      url: `${baseUrl}/v1/campaigns/${campaignId}`,
      method: 'put',
      timeout: 30 * 1000,
      data: campaignData
    });
  }
  return null;
};

export const deleteCampaign = async (accessToken: string, campaignId: string) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>(accessToken, {
      url: `${baseUrl}/v1/campaigns/${campaignId}`,
      method: 'delete',
      timeout: 30 * 1000
    });
  }
  return null;
};
