import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { ErrorDetails } from '../../store/commonTypes';

export type ApiRequestMethod = 'get' | 'post' | 'head' | 'put' | 'delete';

export interface ApiRequestOptions {
  url: string;
  accessToken?: string;
  method?: ApiRequestMethod;
  query?: { [key: string]: unknown };
  data?: unknown;
  timeout?: number;
  headers?: Record<string, string>;
  responseType?: 'blob' | 'arraybuffer';
  dispatchErrorAction?: (details: object) => void;
  noErrorReportingForStatusCodes?: Array<number>;
  cancelTokenSource?: CancelTokenSource;
}

export interface ApiResponse<T = any> {
  data?: T;
  error?: ErrorDetails;
  status?: number;
  headers?: any;
}

export interface SuccessResponse<T = any> extends ApiResponse<T> {
  data: T;
}

export interface ErrorResponse<T = any> extends ApiResponse<T> {
  error: ErrorDetails;
}

export interface BatchOptions {
  batchSize?: number;
  noThrowErrors?: boolean;
}

export async function batchRequests<T>(promises: Promise<T>[], batchOptions: BatchOptions): Promise<T[]> {
  const batchSize = batchOptions.batchSize || 5;
  let results = [] as T[];

  let prs = promises;
  if (batchOptions.noThrowErrors) {
    prs = promises.map(p => p.catch(e => e));
  }

  for (let i = 0; i < prs.length; i = i + batchSize) {
    const batchResult = await Promise.all(prs.slice(i, i + batchSize));
    results = results.concat(batchResult);
  }

  return results;
}

const getErrorJson = (err: any) => {
  const details = err?.message
      || err?.response?.data
      || { message: 'No further details available' };

  return {
    error: {
      details,
      message: 'Request failed'
    },
    status: err.response && err.response.status
  };
};

export async function apiRequest<T>(accessToken: string, options: ApiRequestOptions): Promise<SuccessResponse<T> | ErrorResponse<T>> {
  const axiosOptions: AxiosRequestConfig = {
    url: options.url,
    method: options.method || 'get',
    params: options.query,
    data: options.data,
    headers: options.headers ?? {},
    timeout: options.timeout ?? 20000,
    responseType: options.responseType,
    cancelToken: options.cancelTokenSource?.token
  };

  axiosOptions.headers.Authorization = `Bearer ${accessToken}`;

  try {
    const response = await axios.request<T>(axiosOptions);
    return {
      data: response.data,
      headers: response.headers,
      status: response.status
    };
  } catch (err) {
    if (axios.isCancel(err)) {
      return null as unknown as ErrorResponse<T>;
    }

    return getErrorJson(err);
  }
}
