import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en-US/translations.json';
import fr from './fr-FR/translations.json';
import de from './de-DE/translations.json';
import nl from './nl-NL/translations.json';
import it from './it-IT/translations.json';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    load: 'currentOnly',
    defaultNS: 'translations',
    resources: {
      en: { translations: en },
      fr: { translations: fr },
      de: { translations: de },
      nl: { translations: nl },
      it: { translations: it }
    }
  });

export { i18n };
