import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssLoader } from '@cimpress/react-components';
import { HeaderX } from './header';
import { Routes } from './routes';
import { useTranslation } from 'react-i18next';
import { AppState } from '../store/store';
import { useSelector } from 'react-redux';
import { FeedbackSticker } from '@cimpress-technology/react-feedback';
import './app.scss';

export const App = () => {
  const { t, i18n } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);
  return (
    <CssLoader>
      <BrowserRouter>
        <HeaderX/>
        <div className='container'>
          <div className='main'>
            <Routes/>
          </div>
        </div>
      </BrowserRouter>
      <FeedbackSticker
        accessToken={accessToken}
        language={i18n.language}
        title={t('feedback.triggerCaption')}
        campaignId={'a29754bc-0aea-11eb-b7c8-02288b18e4b1'}
        questionId={'6c9d1864-0cc1-11eb-82c1-02288b18e4b1'}
      />
    </CssLoader>
  );
};
