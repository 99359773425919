import React, { useState } from 'react';
import { IconButton } from '../iconButton';
import { useTranslation } from 'react-i18next';
import IconDownloadBottom from '@cimpress-technology/react-streamline-icons/lib/IconDownloadBottom';
import { AppState } from '../../store/store';
import { useSelector } from 'react-redux';
import { Snackbar } from '@cimpress/react-components';
import { ErrorDetails } from '../../store/commonTypes';
import * as feedbackAPI from '../../clients/feedbackApi';
import { flattenJson } from '../../utils/helpers';
import { Campaign, CampaignReportResults } from '../../model/campaigns';
import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';

export interface DownloadCampaignReportActionProps {
    disabled?: boolean;
    campaign: Campaign;
}

const reportJsonToBlob = (results: CampaignReportResults|undefined) => {
  const jsonArray = results?._embedded?.response || [];
  const flatJson = jsonArray.map(flattenJson);
  const json2csvParser = new Parser();
  const csv = json2csvParser.parse(flatJson);
  return new Blob([csv], { type: 'text/plain; charset=utf-8' });
};

export const DownloadCampaignReportAction: React.FC<DownloadCampaignReportActionProps> = ({ disabled, campaign }) => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);

  const [downloadingReport, setDownloadingReport] = useState({
    isLoading: false,
    error: null as unknown as ErrorDetails
  });

  const downloadCampaignReports = async () => {
    setDownloadingReport({
      isLoading: true,
      error: null as unknown as ErrorDetails
    });

    const res = await feedbackAPI.getReport(accessToken, campaign.campaignId);
    if (res?.error) {
      setDownloadingReport({
        isLoading: false,
        error: res?.error
      });
    } else {
      setDownloadingReport({
        isLoading: false,
        error: null as unknown as ErrorDetails
      });
      const blob = reportJsonToBlob(res?.data);
      saveAs(blob, `campaign_${campaign.campaignId}_report.csv`);
    }
  };

  return <>

    <IconButton disabled={disabled} size={'3x'} tooltip={t('campaigns.actions.downloadCsv.tooltip')} onClick={() => {
      downloadCampaignReports();
    }} icon={IconDownloadBottom}/>

    <Snackbar
      show={downloadingReport.isLoading || !!downloadingReport.error}
      onHideSnackbar={() => {
        setDownloadingReport({
          isLoading: false,
          error: null as unknown as ErrorDetails
        });
      }}
      bsStyle={downloadingReport.error ? 'danger' : 'info'}>
      {downloadingReport
        ? t('campaigns.downloadingReports')
        : t('campaigns.downloadingReportsError')}
    </Snackbar>

  </>;
};
