import React from 'react';
import { QuestionInfo } from './questionInfo';
import { QuestionForm } from './questionForm';
import { Question } from '../../../model/campaigns';
import { NoQuestions } from './noQuestions';
import { getQuestions } from '../../../store/questions/actions';
import { useDispatch } from 'react-redux';

export interface QuestionsListProps {
    campaignId: string;
    questions: Question[];
    editQuestionId: string|null|undefined;
    onEditQuestion: (questionId: string|null|undefined) => void;
}

export const QuestionsList: React.FC<QuestionsListProps> = ({ campaignId, questions, editQuestionId, onEditQuestion }) => {
  const dispatch = useDispatch();

  if (questions.length === 0) {
    return <NoQuestions onAdd={() => {
      onEditQuestion(null);
    }}/>;
  }

  return <>
    {questions.map(question => {
      //
      if (editQuestionId === question.questionId) {
        return <QuestionForm
          key={question.questionId}
          campaignId={campaignId}
          question={question}
          onCancel={() => {
            onEditQuestion(undefined);
          }}
          onSave={() => {
            onEditQuestion(undefined);
            dispatch(getQuestions(campaignId));
          }}
        />;
      }

      return <QuestionInfo
        key={question.questionId}
        campaignId={campaignId}
        disabled={editQuestionId !== undefined && (editQuestionId !== question.questionId)}
        question={question}
        onEdit={questionId => {
          onEditQuestion(questionId);
        }}
        onDelete={() => {
          dispatch(getQuestions(campaignId));
        }}
      />;
    })}
  </>;
};
