import React from 'react';
import SettingsModal from '@cimpress-technology/react-platform-settings';
import { i18n } from '../../locales/i18n';
import './appSettings.scss';

export interface AppSettingsProps {
  accessToken: string;
}

export const AppSettings: React.FC<AppSettingsProps> = ({ accessToken }) => {
  return <SettingsModal
    className='settingsModal'
    lang={i18n.language}
    authToken={accessToken || 'x'}
    supportedLanguages={Object.keys(i18n.options.resources as object)}
    appSettingsTitle={'FeedbackUI'}
    canSave
  />;
};
