import React from 'react';
import { Drawer, Button } from '@cimpress/react-components';
import { UsersTable } from '@cimpress-technology/react-cimpress-users';
import { useTranslation } from 'react-i18next';

export interface AccessDrawerProps {
  accessToken: string;
  groupUrl: string;
  show: boolean;
  onHide: () => void;
}

export const AccessDrawer: React.FC<AccessDrawerProps> = ({ accessToken, groupUrl, show, onHide }) => {
  const { t, i18n } = useTranslation();

  const onDrawerHide = () => {
    if (onHide) {
      onHide();
    }
  };

  const drawerContent = !groupUrl
    ? <>Missing Group!</>
    : <div style={{ marginTop: '-20px' }}>
      <UsersTable
        language={i18n.language}
        accessToken={accessToken}
        groupUrl={groupUrl}
        mutuallyExclusiveRoles
        showAdminsOnlyFilter={false}
        allowedRoles={[{
          roleName: 'Feedback Campaign Manager',
          roleCaption: 'Editor',
          isManagerRole: true
        }, {
          roleName: 'Feedback Campaign Reader',
          roleCaption: 'Reader',
          isManagerRole: false
        }]}
      />
    </div>;

  return <Drawer
    closeOnClickOutside
    show={show}
    header={
      <div>
        <h4>{t('campaigns.access.drawerTitle')}</h4>
      </div>
    }
    onRequestHide={onDrawerHide}
    footer={<div>
      <Button onClick={onDrawerHide}>
        {t('campaigns.access.buttonTitle')}
      </Button>
    </div>
    } >
    {drawerContent}
  </Drawer>;
};
