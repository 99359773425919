import { apiRequest } from './share/apiClient';

export interface AccountInfo {
  accountId: string;
  name: string;
  status: string;
  cimpressOwned: boolean;
}

const baseUrl = 'https://accounts.cimpress.io';

export const getAccounts = async (accessToken: string) => {
  if (accessToken) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<AccountInfo[]>(accessToken, {
      url: `${baseUrl}/api/v1/accounts`,
      method: 'get',
      timeout: 30 * 1000
    });
  }
  return null;
};
