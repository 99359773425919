/*
 * To anyone editing this file:
 * Please follow the convention of putting the namespace in the enums
 * Without this convention we might accidentally run into collisions
 */

export enum AuthActions {
  REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
  REFRESH_PROFILE = 'AUTH_REFRESH_PROFILE',
  LOGOUT = 'AUTH_LOGOUT',
  FAILURE = 'AUTH_FAILURE'
}

export enum SettingsActions {
  SET_LOCALE = 'SETTINGS_SET_LOCALE',
  SET_SETTNGS = 'SET_SETTNGS',
  SET_COLUMN_WIDTHS = 'SET_COLUMN_WIDTHS'
}

export enum CampaignsActions {
  GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST',
  GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR'
}

export enum QuestionsActions {
  GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST',
  GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR'
}
export enum SnackbarActions {
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR'
}
