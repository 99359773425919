import React, { useState } from 'react';
import { IconButton } from '../iconButton';
import { useTranslation } from 'react-i18next';
import IconMultipleUsers from '@cimpress-technology/react-streamline-icons/lib/IconMultipleUsers';
import { AccessDrawer } from '../accessDrawer';
import { AppState } from '../../store/store';
import { useSelector } from 'react-redux';

export interface AccessManagementActionProps {
    disabled?: boolean;
    groupUrl: string;
}

export const AccessManagementAction: React.FC<AccessManagementActionProps> = ({ disabled, groupUrl }) => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);

  const [accessDrawerShow, setAccessDrawerShow] = useState(false);

  return <>

    <IconButton disabled={disabled} size={'3x'} tooltip={t('campaigns.actions.accessManagement.tooltip')} onClick={() =>
      setAccessDrawerShow(true)} icon={IconMultipleUsers}/>

    <AccessDrawer
      accessToken={accessToken}
      groupUrl={groupUrl}
      show={accessDrawerShow}
      onHide={() => {
        setAccessDrawerShow(false);
      }}/>

  </>;
};
