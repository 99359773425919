import { useAsync } from 'react-async-hook';
import { getUserInfo, UserInfo } from '../../clients/coam';

export const useUserName = (accessToken: string, principalId: string|undefined): UserInfo|undefined => {
  const fetchUser = async () => {
    if (accessToken && principalId) {
      // eslint-disable-next-line no-return-await
      return await getUserInfo(accessToken, principalId);
    }
    return null as unknown as UserInfo;
  };

  const data = useAsync(fetchUser, [accessToken, principalId]);

  return data.result;
};
