import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbItem } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { getQuestions } from '../../store/questions/actions';
import { CampaignInfo } from './campaignInfo';
import { IconButton } from '../iconButton';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import { Question, QuestionLinks, QuestionType } from '../../model/campaigns';
import { Loading } from '../loading';
import { ErrorAlert } from '../errorAlert';
import { QuestionsList } from './questions/questionsList';
import { useCampaignAndQuestions } from './hooks/useCampaignAndQuestions';

const renderBreadcrumbs = (t, campaignTitle: string) => {
  return <Breadcrumbs>
    <BreadcrumbItem><Link to={'/campaigns'}>{t('breadcrumbs.campaigns')}</Link></BreadcrumbItem>
    <BreadcrumbItem active>{campaignTitle}</BreadcrumbItem>
  </Breadcrumbs>;
};

const renderQuestionsHeader = (t, onAddClicked) => {
  return <div className='clearfix'>
    <div className={'pull-right'}>
      <IconButton tooltip={t('campaigns.buttonCreate')} onClick={onAddClicked} icon={IconAddCircle}/>
    </div>
    <h2>{t('campaign.questions')}</h2>
  </div>;
};

const getDefaultQuestion = (): Question => {
  const defaultQuestion: Question = {
    questionId: null,
    title: '',
    type: QuestionType.Stars,
    includeFreeFormField: false,
    freeFormFieldTitle: '',
    _links: {} as unknown as QuestionLinks,
    parameters: {}
  } as Question;

  return Object.assign({}, defaultQuestion) as Question;
};

const getQuestionsToList = (questionIdToEdit: string|undefined|null, campaignQuestions: Question[]) => {
  const result = campaignQuestions.slice();

  // If questionIdToEdit is null, this means we need to add a black form
  if (questionIdToEdit === null && (campaignQuestions.length === 0 || campaignQuestions[0].questionId !== null)) {
    result.unshift(getDefaultQuestion());
  }

  // If questionIdToEdit is undefined, editing of a question is being cancelled
  if (questionIdToEdit === undefined && campaignQuestions.length > 0 && campaignQuestions[0].questionId === null) {
    result.shift();
  }

  return result;
};

export const CampaignDetailsPage: React.FC = () => {
  const { campaignId } = useParams();
  const { campaign,
    campaignsLoading,
    campaignsError,
    campaignQuestions,
    questionsLoading
  } = useCampaignAndQuestions(campaignId);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [questionIdToEdit, setQuestionIdToEdit] = useState(undefined as unknown as string | undefined | null);

  if (campaignsLoading) {
    return <Loading/>;
  }

  if (campaignsError) {
    return <ErrorAlert error={campaignsError}/>;
  }

  return <>
    <div>
      {renderBreadcrumbs(t, campaign.title)}
      <div className={'campaign-details-page'}>
        <div className={'row display-flex'}>
          <div className='col-xs-4'>
            <CampaignInfo campaign={campaign}/>
          </div>
          <div className={'col-xs-8'}>
            <div className='campaign-questions-pane'>
              {renderQuestionsHeader(t, () => setQuestionIdToEdit(null))}
              {!campaignQuestions || questionsLoading
                ? <Loading/>
                : <QuestionsList
                  campaignId={campaignId}
                  questions={getQuestionsToList(questionIdToEdit, campaignQuestions)}
                  editQuestionId={questionIdToEdit}
                  onEditQuestion={questionId => {
                    setQuestionIdToEdit(questionId);
                    if (questionId === undefined) {
                      dispatch(getQuestions(campaignId));
                    }
                  }}/>}

            </div>
          </div>
        </div>
      </div>
    </div>

  </>;
};
