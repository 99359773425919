/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Campaign } from '../../model/campaigns';
import { shapes } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import IconFileCode from '@cimpress-technology/react-streamline-icons/lib/IconFileCode';
import IconAnalyticsPieAlt from '@cimpress-technology/react-streamline-icons/lib/IconAnalyticsPieAlt';
import IconAlarmBell from '@cimpress-technology/react-streamline-icons/lib/IconAlarmBell';
import IconAlarmBellOff from '@cimpress-technology/react-streamline-icons/lib/IconAlarmBellOff';
import { IconButton } from '../iconButton';
import { AppState } from '../../store/store';
import { getCampaigns } from '../../store/campaigns/actions';
import { CampaignDatesInfo } from './campaignDatesInfo';
import { DeleteCampaignAction } from '../actions/deleteCampaignAction';
import { WatchLabel } from '@cimpress-technology/react-baywatch';
import { AccessManagementAction } from '../actions/accessManagementAction';
import { DownloadCampaignReportAction } from '../actions/downloadCampaignReportAction';
import { CampaignForm } from './campaignForm';

const { Spinner } = shapes;

export interface CampaignInfoProps {
    campaign: Campaign;
}

const renderActionBar = (t, i18n, accessToken: string, campaign: Campaign) => {
  return <div className='flexContainer actionBar'>
    <div className='flexChild'>
      <IconButton disabled={true} size={'3x'} tooltip={t('campaigns.buttonGetCode')} onClick={() => {
      // disable for now as we don't have clear vision on how to show it.
      }} icon={IconFileCode}/>
    </div>
    <div className='flexChild'>
      <AccessManagementAction groupUrl={campaign?._links?.coamGroup?.href || ''}/>
    </div>
    <div className='flexChild'>
      <DownloadCampaignReportAction campaign={campaign}/>
    </div>
    <div className='flexChild'>
      <Link to={`/campaigns/${campaign?.campaignId}/results`}>
        <IconButton size={'3x'} tooltip={t('campaigns.actions.results.tooltip')} icon={IconAnalyticsPieAlt}/>
      </Link>
    </div>
    <div className='flexChild'>
      <WatchLabel
        locale={i18n.language}
        accessToken={accessToken}
        resourceUri={campaign?._links?.self?.href}
        toolTipDirection={'top'}
        tooltipOnError={t('campaigns.actions.subscription.errorTooltip')}
        tooltipSubscribeOverride={t('campaigns.actions.subscription.inactiveTooltip')}
        tooltipUnsubscribeOverride={t('campaigns.actions.subscription.activeTooltip')}
        refreshInterval={100000}
        labelOnError={<IconButton disabled={true} size={'3x'} icon={IconAlarmBell} stopOnClickPropagation={false}/>}
        labelOnInProgress={<Spinner size={'medium'} />}
        labelOnSubscriptionActive={ <IconButton size={'3x'} icon={IconAlarmBell} stopOnClickPropagation={false}/>}
        labelOnSubscriptionInactive={<IconButton size={'3x'} icon={IconAlarmBellOff} stopOnClickPropagation={false}/>}
      />
    </div>
  </div>;
};

export const CampaignInfo: React.FC<CampaignInfoProps> = ({ campaign }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { accessToken } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  return <div className={'campaign-info-pane'}>

    {renderActionBar(t, i18n, accessToken, campaign)}

    <br/>

    <CampaignForm
      campaign={campaign}
      onSaving={isSaving => {
        setSaving(isSaving);
      }}/>

    <br/>

    <CampaignDatesInfo campaign={campaign}/>

    <DeleteCampaignAction
      disabled={saving}
      campaignId={campaign.campaignId}
      campaignTitle={campaign.title}
      onDeleted={() => {
        dispatch(getCampaigns());
        history.push('/campaigns');
      }}
    />
    
  </div>;
};
