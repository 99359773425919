import React, { useState } from 'react';
import { Button, colors, Modal } from '@cimpress/react-components';
import { IconButton } from '../iconButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { ErrorDetails } from '../../store/commonTypes';
import { ErrorAlert } from '../errorAlert';
import * as feedbackAPI from '../../clients/feedbackApi';
import { AppState } from '../../store/store';

export interface DeleteQuestionActionProps {
    disabled: boolean;
    campaignId: string;
    questionId: string;
    questionTitle: string|null;
    onDeleted: () => void;
}

const modalFooter = (t, deleting, closeModal, confirmDelete) => {
  return <>
    <Button disabled={deleting} type='default' onClick={closeModal}>
      {t('questions.remove.buttonCancel')}
    </Button>
    &nbsp;
    <Button disabled={deleting} type='primary' onClick={confirmDelete}>
      {t('questions.remove.buttonConfirm')}
    </Button>
  </>;
};

const modalContent = (t, questionTitle, error) => {
  return <>
    <ErrorAlert error={error}/>
    <div>{t('questions.remove.modalDescription')}</div>
    <br/>
    <div>{t('questions.remove.modalDescription1')}</div>
    <br/>
    <strong>{questionTitle}</strong>
  </>;
};

export const DeleteQuestionAction: React.FC<DeleteQuestionActionProps> = ({ disabled, campaignId, questionId, questionTitle, onDeleted }) => {
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const [showDeleteQuestionConfirmation, setShowDeleteQuestionConfirmation] = useState(false);
  const [error, setError] = useState(null as unknown as ErrorDetails);
  const [deleting, setDeleting] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setShowDeleteQuestionConfirmation(true);
  };

  const closeModal = () => {
    setShowDeleteQuestionConfirmation(false);
    setError(null as unknown as ErrorDetails);
  };

  const confirmDelete = async () => {
    setDeleting(true);
    setError(null as unknown as ErrorDetails);
    const res = await feedbackAPI.deleteQuestion(accessToken, campaignId, questionId);
    setDeleting(false);
    if (res?.error) {
      setError(res.error);
    } else {
      setShowDeleteQuestionConfirmation(false);
      if (onDeleted) {
        onDeleted();
      }
    }
  };

  return <>

    <IconButton
      size={'2x'}
      color={disabled ? colors.alloy : undefined}
      tooltip={t('questions.buttonRemove')}
      onClick={disabled ? undefined : showModal}
      icon={IconBin}/>

    <Modal
      bsStyle={'danger'}
      show={showDeleteQuestionConfirmation}
      onRequestHide={closeModal}
      title={t('questions.remove.modalTitle')}
      closeButton={!deleting}
      footer={modalFooter(t, deleting, closeModal, confirmDelete)}>
      {modalContent(t, questionTitle, error)}
    </Modal>

  </>;
};
