import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { authReducer } from './auth/reducers';
import * as AuthActions from './auth/actions';
import { settingsReducer } from './settings/reducers';
import { campaignsReducer } from './campaigns/reducers';
import { questionsReducer } from './questions/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  campaigns: campaignsReducer,
  questions: questionsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

function configureStore() {
  const middlewares = [
    thunkMiddleware,
    routerMiddleware(createBrowserHistory())
  ];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  /**
   * Bootstrapping store objects
   * store.dispatch is not compatible with redux-thunk, so we need to do this cast
   */
  (store.dispatch as ThunkDispatch<AppState, void, AnyAction>)(AuthActions.initializeAuth());

  return store;
}

export const store = configureStore();
