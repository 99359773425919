import { CampaignsActions } from '../storeActions';
import { AppState } from '../store';
import * as feedbackApi from '../../clients/feedbackApi';
import { Campaign } from '../../model/campaigns';

const getCampaignSuccess = (values: Campaign[]) => ({
  type: CampaignsActions.GET_CAMPAIGNS_SUCCESS,
  data: values
});

const getCampaignError = error => ({
  type: CampaignsActions.GET_CAMPAIGNS_ERROR,
  data: null,
  error: error
});

export const getCampaigns = () => async (dispatch, getState) => {
  dispatch({ type: CampaignsActions.GET_CAMPAIGNS_REQUEST });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    const campaignsResult = await feedbackApi.getCampaigns(accessToken);
    if (campaignsResult?.error) {
      dispatch(getCampaignError(campaignsResult?.error));
    } else if (!campaignsResult?.data?._embedded?.campaign) {
      dispatch(getCampaignError({
        mesage: 'No campaign data'
      }));
    } else {
      dispatch(getCampaignSuccess(campaignsResult?.data?._embedded?.campaign));
    }
  } catch (e) {
    dispatch(getCampaignError(e));
  }
};
