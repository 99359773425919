import React from 'react';
import { colors, Tooltip } from '@cimpress/react-components';

export interface IconButtonProps {
    tooltip?: string;
    disabled?: boolean;
    onClick?: (e) => void;
    icon: any;
    size?: string;
    color?: string;
    stopOnClickPropagation?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({ disabled, tooltip, onClick, icon, size, color, stopOnClickPropagation }) => {
  const Icon = icon;

  const onClickLocal = e => {
    if (onClick) {
      if (stopOnClickPropagation === undefined || stopOnClickPropagation) {
        e.stopPropagation();
      }
      onClick(e);
    }
  };

  const content = <span className={'icon-button'} onClick={disabled ? undefined : onClickLocal}>
    <Icon size={size} color={disabled ? colors.alloy : color }/>
  </span>;

  if (!tooltip) {
    return content;
  }

  return <Tooltip contents={tooltip}>
    {content}
  </Tooltip>;
};

IconButton.defaultProps = {
  size: '3x',
  color: colors.info.darker
};
