import { CampaignReportResultLine, Question } from '../../model/campaigns';
import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { renderTwoColumns } from '../utils';
import { Select, Alert } from '@cimpress/react-components';
import { useAsync } from 'react-async-hook';
import { getAccounts } from '../../clients/accounts';
import { AppState } from '../../store/store';
import { useSelector } from 'react-redux';

export const chartColors: string[] = [
  '#59ac82',
  '#83ac70',
  '#fea23f',
  '#ff823a',
  '#fc5e3a'
];

export const defaultChartColors: string[] = [
  '#92d5f0',
  '#43b6dd',
  '#35adb8',
  '#59ac82',
  '#83ac70',
  '#fea23f',
  '#ff823a',
  '#fc5e3a',
  '#cd4e3e',
  '#864a4a',
  '#53464C'
];

export const chartColorsReversed: string[] = chartColors.slice().reverse();

const getStarLabels = t => [1, 2, 3, 4, 5].map(i => t('results.starsWithCount', { count: i })) as string[];

export const chartTwoColors: string[] = [
  '#43b6dd',
  '#fea23f'
];

export interface QuestionResultsLineProps {
    question: Question;
    data: CampaignReportResultLine[];
}

const renderDoughnut = (t, labels, data, dataLabel, colors?) => {
  return <Doughnut
    options={{
      legend: {
        position: 'right'
      }
    }}
    data={{
      labels: labels,
      datasets: [{
        data: data,
        label: dataLabel,
        borderWidth: 1,
        backgroundColor: colors ?? chartColorsReversed
      }]
    }} />;
};

const renderList = (texts: React.ReactNode[]) => {
  return <ul>
    {texts.map((q, i) => {
      return <li key={i} >
        {q}
      </li>;
    })}
  </ul>;
};

const countUser = (uniqueUsers: Record<string, number[]>, resultLint: CampaignReportResultLine) => {
  const userId = resultLint.userInfo.email ?? resultLint.userInfo.userId;
  if (!uniqueUsers[userId]) {
    uniqueUsers[userId] = [];
  }
  uniqueUsers[userId].push(parseInt(resultLint.value));
};

const countBusiness = (uniqueBusinesses: Record<string, number[]>, resultLint: CampaignReportResultLine) => {
  const accountId = resultLint.userInfo.accountID ?? 'n/a';
  if (!uniqueBusinesses[accountId]) {
    uniqueBusinesses[accountId] = [];
  }
  uniqueBusinesses[accountId].push(parseInt(resultLint.value));
};

const getStats = (data: CampaignReportResultLine[], extraKey) => {
  const votesPerStart = [0, 0, 0, 0, 0, 0];
  const votesPerComment = [0, 0];
  const freeFormComments: string[] = [];
  const uniqueUsers: Record<string, number[]> = {};
  const uniqueBusinesses: Record<string, number[]> = {};
  let totalResponses = 0;

  const extraKeys = {};
  (data || []).forEach(q => {
    Object.keys(q.extra || {}).forEach(kkk => extraKeys[`${kkk} :: ${q.extra[kkk]}`] = { key: kkk, data: q.extra[kkk] });
  });

  (data || []).forEach(q => {
    if (extraKey && (q.extra || {})[extraKey.value.key] !== extraKey.value.data) {
      return;
    }

    countUser(uniqueUsers, q);
    countBusiness(uniqueBusinesses, q);

    totalResponses++;

    if (q.questionType === 'TextField') {
      freeFormComments.push(q.value);
    }

    votesPerStart[parseInt(q.value) - 1]++;
    if (q.text && q.text.length > 0) {
      freeFormComments.push(q.text);
      votesPerComment[1]++;
    } else {
      votesPerComment[0]++;
    }
  });

  return { votesPerComment, votesPerStart, freeFormComments, totalResponses, extraKeys, uniqueUsers, uniqueBusinesses };
};

const renderKeysByCountDesc = (votesMap: Record<string, number[]>, linkTo?: string) => {
  const countMap: Record<string, number> = {};
  Object.keys(votesMap).forEach(k => {
    const sum = votesMap[k].reduce((a, b) => a + b, 0);
    countMap[k] = (sum / votesMap[k].length) || 0;
  });

  const users = Object.keys(countMap);
  users.sort((a, b) => countMap[b] - countMap[a]);
  const itemsToRender = linkTo
    ? users.map((u, i) => <><a key={i} href={`${linkTo}${u}`}>{u}</a> (rate {countMap[u].toFixed(1)})</>)
    : users.map(u => <>{u} (rate: {countMap[u].toFixed(1)})</>);
  return renderList(itemsToRender);
};

const renderResultsByUserAndBusiness = (t, uniqueUsers, uniqueBusinesses, accounts) => {
  let namedBusiness = {};
  if (accounts.result?.data) {
    for (const acc of accounts.result.data._embedded.item) {
      if (uniqueBusinesses[acc.accountId]) {
        namedBusiness[acc.name] = uniqueBusinesses[acc.accountId];
      }
    }
  } else {
    namedBusiness = uniqueBusinesses;
  }
  console.log(namedBusiness);

  return renderTwoColumns(
    <div>
      <h5>{t('results.byUsers')}</h5>
      {renderKeysByCountDesc(uniqueUsers)}
    </div>,
    <div>
      <h5>{t('results.byBusinesses')}</h5>
      {renderKeysByCountDesc(namedBusiness, 'https://accounts.cimpress.io/')}
    </div>
  );
};

const renderFreeFormComments = (label, freeFormComments) => {
  return <div>
    <h5>{label}</h5>
    {renderTwoColumns(
      renderList(freeFormComments.slice(0, freeFormComments.length / 2 + 1)),
      renderList(freeFormComments.slice(freeFormComments.length / 2 + 1))
    )}
  </div>;
};

const renderStarsReport = (t, votesPerStart, votesPerComment, freeFormComments, uniqueUsers, uniqueBusinesses, accounts) => {
  return <div>
    {renderTwoColumns(
      renderDoughnut(t, getStarLabels(t), votesPerStart, 'Stars'),
      renderDoughnut(t, [
          t('results.withoutComments') as string,
          t('results.withComments') as string
      ], votesPerComment, 'Stars', chartTwoColors)
    )}
    <br/><br/>

    {renderResultsByUserAndBusiness(t, uniqueUsers, uniqueBusinesses, accounts)}

    {freeFormComments.length > 0
      ? <div>
        <br/><br/>
        {renderFreeFormComments(t('results.freeFormTexts'), freeFormComments)}
      </div>
      : null}
  </div>;
};

export const QuestionResultsLine: React.FC<QuestionResultsLineProps> = ({ question, data }) => {
  const { t } = useTranslation();
  const [extraKey, setExtraKey] = useState(null as any);
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const accounts = useAsync(getAccounts, [accessToken]);
  const questionType = data[data.length - 1]?.questionType;

  const { votesPerStart, votesPerComment, freeFormComments, totalResponses, extraKeys, uniqueUsers, uniqueBusinesses } = getStats(data, extraKey);

  const extraOptions = Object.keys(extraKeys).map(a => ({ label: a, value: extraKeys[a] }));
  extraOptions.sort((a, b) => a.label.localeCompare(b.label));

  return <div className='result-container'>
    <h2>{question.title}</h2>
    <div className='result-container-contents'>
      <Select
        isClearable
        isDisabled={extraOptions.length === 0}
        label={t('results.filterLabel')}
        value={extraKey as any}
        options={extraOptions}
        onChange={v => {
          setExtraKey((v as unknown as any));
        }}
        tether
      />
      <div style={{ textAlign: 'center' }}>
        Total responses: <strong>{totalResponses}</strong>
      </div>
      {questionType === 'Stars'
        && renderStarsReport(t, votesPerStart, votesPerComment, freeFormComments, uniqueUsers, uniqueBusinesses, accounts)
      }

      {questionType === 'TextField'
        && <>
          {freeFormComments.length > 0
            ? renderFreeFormComments(t('results.freeFormTexts'), freeFormComments)
            : null}
        </>
      }

      {['SingleChoice', 'MultipleChoice'].includes(questionType)
        && <div>
          <br/>
          <Alert type='info' message={t('results.underProgress')} dismissible={false} />
        </div>
      }
    </div>
  </div>;
};
