import moment from 'moment';

export const formatDateAndTime = (dt?: string) => {
  // TODO: respect user locale
  return moment.utc(dt).format('YYYY-MM-DD HH:MM');
};

export const flattenJson = json => {
  const res = {};
  Object.keys(json).forEach(key => {
    const val = json[key];
    if (typeof val === 'object' && val !== null) {
      Object.keys(val).forEach(subKey => {
        res[`${key}.${subKey}`] = val[subKey];
      });
    } else {
      res[key] = json[key];
    }
  });
  return res;
};
