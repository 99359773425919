import { getPrincipal } from 'coam-client';

import {
  getCachedResultByKey,
  getPendingPromiseByKey,
  setCachedResultAndClearPendingPromiseForKey,
  setPendingPromiseForKey
} from './share/runningPromiseCache';

export interface UserInfo {
  canonical_principal: string;
  account_id: string;
  profile: {
    name: string;
    email: string;
  };
}

export const getUserInfo = async (accessToken, principalId): Promise<UserInfo> => {
  const alreadyCached = getCachedResultByKey(principalId);
  if (alreadyCached) {
    return alreadyCached;
  }

  let responsePromise = getPendingPromiseByKey(principalId);
  if (!responsePromise) {
    responsePromise = getPrincipal(accessToken, principalId);
  }

  setPendingPromiseForKey(principalId, responsePromise);
  const response = (await responsePromise);

  setCachedResultAndClearPendingPromiseForKey(principalId, response);

  return response;
};
