import { SettingsActions } from '../storeActions';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { getMcpSettings, CustomizrMcpSettingsResponse } from 'cimpress-customizr';

export const setSettings = settings => ({ type: SettingsActions.SET_SETTNGS, settings });

export const getCustomizrSettings = (): ThunkAction<void, AppState, void, AnyAction> => async (dispatch, getState) => {
  const { accessToken } = getState().auth;
  // const userId = profile?.email || profile?.sub;

  try {
    const settings = await Promise.all([
      // (new CustomizrClient({}).getSettings(accessToken, pomApplicationResourceId) as Promise<CustomizrPomV2>),
      getMcpSettings(accessToken) as CustomizrMcpSettingsResponse
    ]);

    const allSettings = settings.reduce((acc, setting) => Object.assign(acc, setting), {});

    allSettings.language = allSettings.language[0];
    allSettings.locale = allSettings.regionalSettings;

    return dispatch(setSettings(allSettings));
  } catch (e) {
    return dispatch(setSettings({}));
  }
};
