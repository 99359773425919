import React from 'react';
import { AppState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbItem, Card } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { QuestionResultsLine } from './questionResults';
import { useAsync } from 'react-async-hook';
import * as feedbackAPI from '../../clients/feedbackApi';

import './index.scss';
import { Loading } from '../loading';
import { ErrorAlert } from '../errorAlert';
import { useCampaignAndQuestions } from '../campaignDetails/hooks/useCampaignAndQuestions';
import { InlineFeedback } from '@cimpress-technology/react-feedback';

export const CampaignResultsPage: React.FC = () => {
  const { campaignId } = useParams();
  const { campaign, campaignsLoading, campaignsError, campaignQuestions } = useCampaignAndQuestions(campaignId);
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();

  const campaignResult = useAsync(feedbackAPI.getReport, [accessToken, campaignId]);

  if (campaignsLoading || campaignResult.loading) {
    return <Loading/>;
  }

  if (campaignsError) {
    return <ErrorAlert error={campaignsError}/>;
  }

  return <>
    <div>
      <Breadcrumbs>
        <BreadcrumbItem><Link to={'/campaigns'}>{t('breadcrumbs.campaigns')}</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to={`/campaigns/${campaignId}`}>{campaign.title}</Link></BreadcrumbItem>
        <BreadcrumbItem active>{t('breadcrumbs.campaignReports')}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <div className=''>
          {campaignQuestions.map((question, i) => {
            return <QuestionResultsLine
              key={i}
              question={question}
              data={(campaignResult.result?.data?._embedded.response || []).filter(q => q.questionId === question.questionId)}/>;
          })}
        </div>
        <InlineFeedback
          accessToken={accessToken}
          campaignId={'a29754bc-0aea-11eb-b7c8-02288b18e4b1'}
          questionId={'161f71b2-1211-11eb-b0b4-0a6d98ccf6f1'}
          extraFeedbackData={{
            page: 'Feedback Results Chart',
            campaignId,
            campaignResponses: campaign.totalResponses
          }}
        />
      </Card>
    </div>

  </>;
};
