import React, { useState } from 'react';
import { IconButton } from '../iconButton';
import { useTranslation } from 'react-i18next';
import IconFileCode from '@cimpress-technology/react-streamline-icons/lib/IconFileCode';
import { CodeDrawer } from '../codeDrawer';

export interface GetCodeQuestionActionProps {
    disabled: boolean;
    campaignId: string;
    questionId: string;
}

export const GetCodeQuestionAction: React.FC<GetCodeQuestionActionProps> = ({ disabled, campaignId, questionId }) => {
  const { t } = useTranslation();

  const [codeDrawerShow, setCodeDrawerShow] = useState(false);

  return <>

    <IconButton
      disabled={disabled}
      icon={IconFileCode}
      size={'2x'}
      tooltip={t('questions.buttonGetCode')}
      onClick={() => {
        setCodeDrawerShow(true);
      }} />

    <CodeDrawer
      campaignId={campaignId}
      questionId={questionId}
      show={codeDrawerShow}
      onHide={() => {
        setCodeDrawerShow(false);
      }}/>

  </>;
};
