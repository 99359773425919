import React from 'react';

export const renderTwoColumns = (left: React.ReactNode, right: React.ReactNode): React.ReactNode => {
  return <div className={'row'}>
    <div className={'col-xs-6'}>{left}</div>
    <div className={'col-xs-6'}>{right}</div>
  </div>;
};

export const renderThreeColumns = (left: React.ReactNode, middle: React.ReactNode, right: React.ReactNode): React.ReactNode => {
  return <div className={'row'}>
    <div className={'col-xs-4'}>{left}</div>
    <div className={'col-xs-4'}>{middle}</div>
    <div className={'col-xs-4'}>{right}</div>
  </div>;
};
