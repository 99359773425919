import React from 'react';
import { IconButton } from '../iconButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconView from '@cimpress-technology/react-streamline-icons/lib/IconView';
import { AppState } from '../../store/store';
import { FeedbackWrapper, IntegrationEnvironment, ProductionEnvironment } from '@cimpress-technology/react-feedback';
import { isProd } from '../../utils/environmentProvider';

export interface PreviewQuestionActionProps {
    disabled: boolean;
    campaignId: string;
    questionId: string;
}

export const PreviewQuestionAction: React.FC<PreviewQuestionActionProps> = ({ disabled, campaignId, questionId }) => {
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();

  return <>
    <FeedbackWrapper
      accessToken={accessToken}
      environment={{ ...isProd
        ? ProductionEnvironment
        : IntegrationEnvironment, ...{
        useCache: false
      } }}
      campaignId={campaignId}
      questionId={questionId || ''} >
      <IconButton
        size={'2x'}
        disabled={disabled}
        tooltip={t('questions.buttonPreview')}
        icon={IconView}/>
    </FeedbackWrapper>
  </>;
};
