import { SettingsActionTypes, SettingsState } from './types';
import { SettingsActions } from '../storeActions';

const initialState: SettingsState = {
  language: 'en',
  locale: 'en'
};

export function settingsReducer(state = initialState, action: SettingsActionTypes): SettingsState {
  if (action.type === SettingsActions.SET_SETTNGS) {
    return {
      ...state,
      ...action.settings
    };
  }
  return state;
}
