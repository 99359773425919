import React, { useEffect, useState } from 'react';
import { AppState } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getCampaigns } from '../../store/campaigns/actions';
import { CampaignTable } from './campaignTable';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../iconButton';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import { NewCampaignModal } from '../newCampaignModal';
import { Loading } from '../loading';
import { ErrorAlert } from '../errorAlert';
import { useHistory } from 'react-router';

export const CampaignsPage: React.FC = () => {
  const campaigns = useSelector((state: AppState) => state.campaigns);
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [showNewCampaignModal, setShowNewCampaignModal] = useState(false);

  useEffect(() => {
    if ((campaigns.data === null || campaigns.data === undefined) && !campaigns.isLoading && !campaigns.error && accessToken) {
      dispatch(getCampaigns());
    }
  }, [accessToken, campaigns, dispatch]);

  if (campaigns.isLoading) {
    return <Loading/>;
  }

  if (campaigns.error) {
    return <ErrorAlert error={campaigns.error}/>;
  }

  return <>
    <NewCampaignModal
      accessToken={accessToken}
      show={showNewCampaignModal}
      onCancel={() => {
        setShowNewCampaignModal(false);
      }}
      onSave={campaignId => {
        setShowNewCampaignModal(false);
        dispatch(getCampaigns());
        history.push(`/campaigns/${campaignId}`);
      }}
    />
    <div className='action-bar clearfix'>
      <div className='pull-left'>
        <h2>{t('campaigns.title')}</h2>
      </div>
      <IconButton tooltip={t('campaigns.buttonCreate')} onClick={() => {
        setShowNewCampaignModal(true);
      }} icon={IconAddCircle}/>
    </div>
    <CampaignTable campaigns={campaigns.data || [] }/>
  </>;
};
