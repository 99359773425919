import { formatDateAndTime } from '../../utils/helpers';
import React from 'react';
import { CampaignInfoProps } from './campaignInfo';
import { useTranslation } from 'react-i18next';
import { useUserName } from '../hooks/useUserName';
import { AppState } from '../../store/store';
import { useSelector } from 'react-redux';

export const CampaignDatesInfo: React.FC<CampaignInfoProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const createdByUser = useUserName(accessToken, campaign?.createdBy);
  const updatedByUser = useUserName(accessToken, campaign?.updatedBy);

  const renderBlock = (name, value) => {
    return <div className={'info-group'}>
      <h6 className={'info-group-name'}>
        {name}
      </h6>
      <div className={'info-group-value'}>
        {value}
      </div>
    </div>;
  };

  return <div className='campaign-dates-info'>
    {renderBlock(t('campaigns.fields.updatedBy.label'), updatedByUser?.profile?.name || campaign.updatedBy || createdByUser?.profile?.name || campaign.createdBy)}
    {renderBlock(t('campaigns.fields.updatedAt.label'), formatDateAndTime(campaign.updatedAt || campaign.createdAt))}
    {renderBlock(t('campaigns.fields.createdBy.label'), createdByUser?.profile?.name || campaign.createdBy)}
    {renderBlock(t('campaigns.fields.createdAt.label'), formatDateAndTime(campaign.createdAt))}
  </div>;
};
