import { QuestionsActionTypes } from './types';
import { QuestionsActions } from '../storeActions';
import { Question } from '../../model/campaigns';

export interface CampaignsState {
  byCampaignId: Record<string, QuestionState>;
}

const initialState: CampaignsState = {
  byCampaignId: {
  }
};

export interface QuestionState {
  isLoading: boolean;
  data: Question[] | null;
  error: any;
}

export function questionsReducer(state = initialState, action: QuestionsActionTypes): CampaignsState {
  const byId = JSON.parse(JSON.stringify(state.byCampaignId));
  switch (action.type) {
    case QuestionsActions.GET_QUESTIONS_REQUEST:
      return {
        ...state,
        byCampaignId: Object.assign({}, byId, {
          [action.campaignId]: {
            isLoading: true,
            data: null,
            error: null
          }
        })
      };
    case QuestionsActions.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        byCampaignId: Object.assign({}, byId, {
          [action.campaignId]: {
            isLoading: false,
            data: action.data,
            error: null
          }
        })
      };
    case QuestionsActions.GET_QUESTIONS_ERROR:
      return {
        ...state,
        byCampaignId: Object.assign({}, byId, {
          [action.campaignId]: {
            isLoading: false,
            data: null,
            error: action.error
          }
        })
      };
    default:
      return state;
  }
}
