import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, shapes } from '@cimpress/react-components';

const { Robot } = shapes;

export interface NoQuestionsProps {
    onAdd: () => void;
}

export const NoQuestions: React.FC<NoQuestionsProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  return <div style={{ paddingTop: '20px' }}>
    <Alert type={'info'} message={<>
      <div style={{ marginBottom: '20px' }}>
        {t('campaigns.noQuestionsDefined')}
      </div>
      <Button
        size={'sm'}
        type={'default'}
        onClick={onAdd}
      >{t('campaigns.buttonCreate')}
      </Button>
    </>} dismissible={false}/>
    <div className={'clearfix'}>
      <div className={'pull-right'}>
        <Robot bsStyle={'info'} size='sm' />
      </div>
    </div>
  </div>;
};
