import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { FourOhFour, shapes } from '@cimpress/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import * as authActions from '../store/auth/actions';
import { CampaignsPage } from '../components/campaigns';
import { CampaignDetailsPage } from '../components/campaignDetails';
import { CampaignResultsPage } from '../components/campaignResults';

const NamedRoute: React.FC<CustomRouteProps> = ({ component: Component, path, title, ...props }) => {
  const defaultPageTitle = 'Cimpress Feedback';
  document.title = title ? `${defaultPageTitle} - ${title}` : defaultPageTitle;
  useEffect(() => {
    if (path) {
      ReactGA.pageview(Array.isArray(path) ? path[0] : path, undefined, title);
    }
  }, [path, title]);

  return (
    <Route
      {...props}
      render={routeProps => (<Component {...routeProps} />)}
    />
  );
};

export const Routes: React.FC = () => {
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accessToken) {
      dispatch(authActions.login());
    }
  }, [accessToken, dispatch]);

  if (!accessToken) {
    const { Spinner } = shapes;
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner/>
      </div>
    );
  }

  return (
    <Switch>
      <NamedRoute key={'Dashboard'} exact path={'/'} component={CampaignsPage} title={'Dashboard'} />,
      <NamedRoute key={'Campaigns'} exact path={'/campaigns'} component={CampaignsPage} title={'Campaigns'} />,
      <NamedRoute key={'Campaign Details'} exact path={'/campaigns/:campaignId'} component={CampaignDetailsPage} title={'Campaigns'} />,
      <NamedRoute key={'Campaign Results'} exact path={'/campaigns/:campaignId/results'} component={CampaignResultsPage} title={'Results'} />,
      <NamedRoute key={'404'} component={FourOhFour} title={'404'}/>
    </Switch>
  );
};

interface CustomRouteProps extends RouteProps {
  title: string;
  exact?: boolean;
  path?: string;
  component: React.ComponentType<any>;
}
